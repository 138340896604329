import React from 'react';
import { Stack, styled } from '@mui/material';
import { useLocation } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwListSeparator } from '@lib/components/SwListSeparator';
import SwMediaCard from '@lib/components/SwMediaCard';
import { grey3 } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { SwLinkBox, SwLinkOverlay } from '@lib/components/bedrock/navigation/SwLinkBox';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { AspectRatios } from '@lib/enums/apectRatios';
import { slugifyName } from '@lib/helpers/object';
import { boldFontFamily } from '../../BaseLayout';

export type HostCardProps = {
    host: any; // Todo: fix this once codegen is on
    showRole?: boolean;
    sessions?: any[];
    /** Use this props to make possible to click on the card to redirect the user */
    to?: string;
};

const LogoCard = styled(SwPaper)`
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 30%;
    max-height: 64px;
    padding-block: 8px;
    aspect-ratio: ${AspectRatios.widescreen};
    z-index: 1;
`;

const Dot = styled('span')`
    inline-size: 4px;
    block-size: 4px;
    background: ${grey3};
    border-radius: 90px;
    display: inline-block;
`;

export const HostCard: React.FC<HostCardProps> = (props) => {
    const { host, showRole = true, sessions = [] } = props;

    const { search } = useLocation();

    const sessionsTarget = sessions
        .filter((session) => session.hosts?.map(({ person: { id } }) => id).includes(host.person.id))
        .at(0)?.title;

    return (
        <SwLinkBox>
            <SwMediaCard
                aspectRatio={AspectRatios.landscape}
                background={'white'}
                interactive={Boolean(sessionsTarget)}
                key={host.id}
                layout={'content-bottom'}
                media={
                    <>
                        {host.person.company?.logo && (
                            <LogoCard>
                                <GatsbyImage
                                    alt={host.person.company.logo.alt}
                                    image={host.person.company.logo.gatsbyImageData}
                                    objectFit={'contain'}
                                    style={{ height: '100%', width: '100%' }}
                                />
                            </LogoCard>
                        )}
                        {host.person.photo ? (
                            <GatsbyImage
                                alt={host.person.photo.alt}
                                class={'host-image'}
                                image={host.person.photo?.gatsbyImageData}
                                objectFit={'cover'}
                                style={{ height: '100%', width: '100%' }}
                            />
                        ) : null}
                    </>
                }
            >
                <Stack alignItems={'start'} height={'100%'} spacing={1}>
                    {showRole && (
                        <SwTypography bold={true} color={grey3} uppercase={true} variant={'caption'}>
                            {host.role}
                        </SwTypography>
                    )}
                    <Stack spacing={2}>
                        <div>
                            <SwTypography component={'h2'} variant={'headingStrong'}>
                                {sessionsTarget ? (
                                    <SwLinkOverlay replace={false} to={`${search}#${slugifyName(sessionsTarget)}`}>
                                        {host.person.firstName} {host.person.lastName}
                                    </SwLinkOverlay>
                                ) : (
                                    <>
                                        {host.person.firstName} {host.person.lastName}
                                    </>
                                )}
                            </SwTypography>
                            <SwTypography color={grey3} variant={'body2'}>
                                {host.person.role}
                            </SwTypography>
                        </div>
                        {Boolean(host.person.bio) && (
                            <SwTypography color={grey3} variant={'body2'}>
                                {host.person.bio}
                            </SwTypography>
                        )}
                    </Stack>

                    <SwListSeparator
                        alignItems={'center'}
                        direction={'row'}
                        gap={'1px'}
                        separator={<Dot />}
                        style={{ marginInlineStart: -4 }}
                    >
                        {sessionsTarget ? (
                            <SwInternalLink
                                key={1}
                                replace={false}
                                size={'small'}
                                style={{
                                    fontFamily: boldFontFamily,
                                    color: grey3
                                }}
                                to={`${search}#${slugifyName(sessionsTarget)}`}
                                variant={'link'}
                            >
                                Session
                            </SwInternalLink>
                        ) : null}
                        {host.person.profile ? (
                            <SwInternalLink
                                key={2}
                                size={'small'}
                                style={{
                                    fontFamily: boldFontFamily,
                                    color: grey3
                                }}
                                to={`${WEBSITE_MENU_ITEM.profiles.link}/${host.person.profile.slug}`}
                                variant={'link'}
                                withI18n={false}
                            >
                                Bio
                            </SwInternalLink>
                        ) : null}
                        {host.person.links?.linkedin && (
                            <SwInternalLink
                                href={host.person.links.linkedin}
                                key={3}
                                rel={'noreferrer'}
                                size={'small'}
                                style={{
                                    fontFamily: boldFontFamily,
                                    color: grey3
                                }}
                                target={'_blank'}
                                variant={'link'}
                            >
                                Linkedin
                            </SwInternalLink>
                        )}
                    </SwListSeparator>
                </Stack>
            </SwMediaCard>
        </SwLinkBox>
    );
};
