import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { SwCollapsibleSection } from '@lib/components/SwCollapsibleSection';
import { grey5, grey3, grey7 } from '@lib/components/bedrock/SwColors';
import SwTag from '@lib/components/bedrock/badges/SwTag';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';

export type EventMetaProps = {
    event: any;
    collapsible?: boolean;
};

const DescriptionList = styled('dl')`
    & > dd:not(:last-child) {
        padding-block-end: 8px;
    }
`;

const TagsContainer = styled(SwFlexBox)`
    margin-block-start: 4px;
`;

const Container = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const Paper = styled('div')`
    width: 100%;
    background-color: ${grey7};
    border: 1px solid ${grey5};
    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(2)};
`;

const EventMetaContent: React.FC<Omit<EventMetaProps, 'collapsible'>> = (props) => {
    const { event, ...rest } = props;
    const { t } = useTranslation();

    return (
        <Container {...rest}>
            <DescriptionList>
                <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                    {t('category')}
                </SwTypography>
                <SwTypography component={'dd'}>{event.category?.label}</SwTypography>
                {event.tags.length ? (
                    <>
                        <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                            {t('topics')}
                        </SwTypography>
                        <SwTypography component={'dd'}>
                            <TagsContainer flexDirection={'row'} flexWrap={'wrap'} gap={8}>
                                {event.tags.map((tag) => (
                                    <SwTag color={tag.color} key={tag.id} size={'small'} uppercase={true}>
                                        {tag.label}
                                    </SwTag>
                                ))}
                            </TagsContainer>
                        </SwTypography>
                    </>
                ) : null}
            </DescriptionList>
        </Container>
    );
};

export const EventMeta: React.FC<EventMetaProps> = (props) => {
    const { collapsible, ...rest } = props;
    const { t } = useTranslation();

    return collapsible ? (
        <SwCollapsibleSection
            title={
                <SwTypography bold={true} color={grey3} component={'h2'} variant={'caption'}>
                    {t('details')}
                </SwTypography>
            }
        >
            <EventMetaContent {...rest} />
        </SwCollapsibleSection>
    ) : (
        <Paper>
            <EventMetaContent {...rest} />
        </Paper>
    );
};
