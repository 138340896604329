import React, { Children } from 'react';
import { Stack, StackProps } from '@mui/material';

export type SwListSeparatorProps = StackProps & {
    children: React.ReactNode;
    separator: React.ReactNode;
};

export const SwListSeparator: React.FC<SwListSeparatorProps> = (props) => {
    const { children, separator, ...rest } = props;

    return (
        <Stack direction={'row'} display={'flex'} gap={1} {...rest}>
            {Children.toArray(children)
                .filter(Boolean)
                .map((child, index) => (
                    <React.Fragment key={index}>
                        {child}
                        {index + 1 < Children.toArray(children).filter(Boolean).length ? separator : null}
                    </React.Fragment>
                ))}
        </Stack>
    );
};
