import { Table, styled } from '@mui/material';
import { grey5, skyBlueLight } from '../SwColors';

export const SwTable = styled(Table)`
    border: solid 1px ${grey5};
    border-radius: 8px;
    border-collapse: separate;
    overflow: hidden;
    width: 100%;

    *,
    & > * {
        box-sizing: border-box;
    }}

    .MuiTableCell-root:last-child,
    .MuiBox-root:last-child {
        border-right: 0;
    }

    .MuiTableRow-hover:hover {
        background-color: ${skyBlueLight};
    }
`;
