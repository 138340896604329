import { Clock, Info } from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import { styled, Stack } from '@mui/material';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { SwTargetLink } from '@lib/components/SwLinkTarget';
import { SwSection } from '@lib/components/SwSection';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import SwTag from '@lib/components/bedrock/badges/SwTag';
import { colors } from '@lib/components/bedrock/fundations/colors';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { SUB_NAV_HEIGHT } from '@lib/components/bedrock/navigation/SwSubNav';
import { SwTable, SwTableBody, SwTableHead, SwTableCell, SwTableRow } from '@lib/components/bedrock/table';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { slugifyName } from '@lib/helpers/object';
import { HostCard, HostCardProps } from './HostsCard';

export type EventSessionProps = {
    sessions: Array<{
        title: string;
        id: string;
        description: string;
        startDate: string;
        hosts: HostCardProps['host'][];
    }>;
};

const Dot = styled('span')`
    inline-size: 4px;
    block-size: 4px;
    background: black;
    border-radius: 90px;
    display: inline-block;
`;

export const EventSessions: React.FC<EventSessionProps> = (props) => {
    const { sessions } = props;

    const { search } = useLocation();

    const sortedSessions = useMemo(
        () =>
            sessions?.sort(
                ({ startDate: startDateA }, { startDate: startDateB }) =>
                    (new Date(startDateA) as any) - (new Date(startDateB) as any)
            ) || [],
        [sessions]
    );
    const speakerSessions = useMemo(
        () => sortedSessions?.filter((session) => session.hosts?.length) ?? [],
        [sortedSessions]
    );

    return (
        <SwSection target={'sessions'}>
            <SwPaper padding={4}>
                <Stack spacing={2}>
                    <SwTypography component={'h2'} variant={'h4'}>
                        Agenda
                    </SwTypography>
                    <SwTable>
                        <SwTableHead>
                            <SwTableRow>
                                <SwTableCell sx={{ maxWidth: '256px' }} variant={'head'}>
                                    Time
                                </SwTableCell>
                                <SwTableCell variant={'head'}>Session</SwTableCell>
                            </SwTableRow>
                        </SwTableHead>
                        <SwTableBody>
                            {sortedSessions.map((session) => (
                                <SwTableRow
                                    key={session.id}
                                    onClick={
                                        session.hosts?.length
                                            ? () =>
                                                  navigate(`${search}#${slugifyName(session.title)}`, {
                                                      replace: false
                                                  })
                                            : undefined
                                    }
                                >
                                    <SwTableCell sx={{ maxWidth: '256px' }} variant={'body'}>
                                        {new Date(session.startDate).toLocaleTimeString('en-EN', {
                                            hour: 'numeric',
                                            minute: 'numeric'
                                        })}
                                    </SwTableCell>
                                    <SwTableCell variant={'body'}>
                                        <Stack
                                            alignItems={'center'}
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            width={'100%'}
                                        >
                                            {session.title}
                                            {speakerSessions.find(({ title }) => title === session.title) ? (
                                                <SwInternalLink
                                                    aria-label={"Scroll to session's details"}
                                                    replace={false}
                                                    size={'small'}
                                                    to={`${search}#${slugifyName(session.title)}`}
                                                    variant={'secondary'}
                                                >
                                                    <Info />
                                                </SwInternalLink>
                                            ) : null}
                                        </Stack>
                                    </SwTableCell>
                                </SwTableRow>
                            ))}
                        </SwTableBody>
                    </SwTable>
                </Stack>
            </SwPaper>
            {speakerSessions.map((session, index) => (
                <SwPaper key={session.id} padding={4}>
                    <SwTargetLink offset={SUB_NAV_HEIGHT + 64} target={slugifyName(session.title)} />
                    <Stack spacing={2}>
                        <SwTag color={'sea'} Icon={<Clock />}>
                            {new Date(session.startDate).toLocaleTimeString('en-EN', {
                                hour: 'numeric',
                                minute: 'numeric'
                            })}
                        </SwTag>
                        <SwTypography component={'h3'} variant={'h4'}>
                            Session {index + 1} <Dot /> {session.title}
                        </SwTypography>
                        <SwTypography color={colors.text.secondary} variant={'body2'}>
                            {session.description}
                        </SwTypography>
                        <SwGrid columns={{ xs: 1, sm: 3 }}>
                            {session.hosts.map((host) => (
                                <HostCard host={host} key={host.id} />
                            ))}
                        </SwGrid>
                    </Stack>
                </SwPaper>
            ))}
        </SwSection>
    );
};
