import {
    CalendarBlank,
    ChatTeardropText,
    Clock,
    MapPin,
    CheckCircle,
    ArrowSquareIn,
    Timer
} from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';
import { useLocation } from '@reach/router';
import { grey3, grey5, white } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import SwTag from '@lib/components/bedrock/badges/SwTag';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';

export type EventInformationProps = {
    location?: {
        addressLine1?: string;
        addressLine2?: string;
        city: string;
        country: string;
        postcode?: string;
    };
    startDate: string;
    isRegistrationClosed: boolean;
    isOnline?: boolean;
    language: string;
    isRegistered?: boolean;
    registrationUrl?: string;
};

const DescriptionList = styled('dl')`
    width: 100%;
    & > dd:not(:last-child) {
        padding-block-end: 8px;
    }
`;

const Container = styled(SwPaper)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const FlexTypography = styled(SwTypography)`
    display: flex;
    align-items: center;
    justify-content: start;
`;

const Dot = styled('span')`
    inline-size: 4px;
    block-size: 4px;
    background: black;
    border-radius: 90px;
    display: inline-block;
`;

const lang = {
    en: 'en-EN',
    fr: 'fr-FR',
    de: 'de-DE'
};

export const EventInformation: React.FC<EventInformationProps> = (props) => {
    const { location, isOnline, startDate, language, isRegistrationClosed, registrationUrl, isRegistered, ...rest } =
        props;
    const { t, i18n } = useTranslation();

    const { search } = useLocation();

    const date = new Date(startDate);
    const dateDescription = `${date.toLocaleDateString(lang[i18n.language], {
        day: 'numeric'
    })} ${date.toLocaleDateString(lang[i18n.language], {
        month: 'long'
    })} ${date.toLocaleDateString(lang[i18n.language], { year: '2-digit' })}`;
    const timeDescription = `${date.toLocaleTimeString(lang[i18n.language], {
        timeStyle: 'short'
    })} ${date
        .toLocaleTimeString(lang[i18n.language], {
            timeZoneName: 'shortGeneric'
        })
        .split(' ')
        .slice(2, 4)
        .join(' ')}`;

    return (
        <Container backgroundColor={white} {...rest}>
            <DescriptionList>
                <Stack alignItems={'center'} direction={'row'} spacing={1} width={'100%'}>
                    {location || isOnline !== undefined ? (
                        <>
                            <FlexTypography
                                bold={true}
                                color={grey3}
                                component={'dt'}
                                gap={0.5}
                                variant={'caption'}
                                width={70}
                            >
                                <MapPin /> {t('location')}
                            </FlexTypography>
                            <FlexTypography gap={1} variant={'bodyStrong'}>
                                {location ? location.city : ''}
                                {isOnline ? (
                                    <>
                                        {location?.city ? <Dot /> : null} {t('online')}
                                    </>
                                ) : null}
                            </FlexTypography>
                        </>
                    ) : null}
                </Stack>
                <SwLine color={grey5} direction={'horizontal'} spacing={1} />
                <Stack alignItems={'center'} direction={'row'} spacing={1} width={'100%'}>
                    <FlexTypography bold={true} color={grey3} component={'dt'} gap={0.5} variant={'caption'} width={70}>
                        <CalendarBlank /> {t('date')}
                    </FlexTypography>
                    <SwTypography variant={'bodyStrong'}>{dateDescription}</SwTypography>
                    {isRegistrationClosed ? (
                        <SwTag color={'error'} Icon={<Timer />}>
                            {t('past_event')}
                        </SwTag>
                    ) : null}
                </Stack>
                <SwLine color={grey5} direction={'horizontal'} spacing={1} />
                <Stack alignItems={'center'} direction={'row'} spacing={1} width={'100%'}>
                    <FlexTypography bold={true} color={grey3} component={'dt'} gap={0.5} variant={'caption'} width={70}>
                        <Clock /> {t('time')}
                    </FlexTypography>
                    <SwTypography variant={'bodyStrong'}>{timeDescription}</SwTypography>
                </Stack>
                <SwLine color={grey5} direction={'horizontal'} spacing={1} />
                <Stack alignItems={'center'} direction={'row'} spacing={1} width={'100%'}>
                    <FlexTypography bold={true} color={grey3} component={'dt'} gap={0.5} variant={'caption'} width={70}>
                        <ChatTeardropText /> {t('language')}
                    </FlexTypography>
                    <SwTypography variant={'bodyStrong'}>{language}</SwTypography>
                </Stack>
            </DescriptionList>
            <SwLine color={grey5} direction={'horizontal'} spacing={1} spacingTop={0} />
            <SwInternalLink
                disabled={isRegistrationClosed}
                href={registrationUrl}
                readonly={isRegistered}
                rel={registrationUrl ? 'noopener' : undefined}
                replace={false}
                target={registrationUrl ? '_blank' : undefined}
                to={registrationUrl ? undefined : `${search}#registration-form`}
                variant={'secondary'}
                withI18n={Boolean(registrationUrl)}
            >
                {isRegistered ? (
                    <>
                        {t('registered')}
                        <CheckCircle />
                    </>
                ) : (
                    <>
                        {t('register')}
                        {registrationUrl ? <ArrowSquareIn /> : null}
                    </>
                )}
            </SwInternalLink>
        </Container>
    );
};
