import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { AspectRatios } from '@lib/enums/apectRatios';
import ResponsiveImg from '@app/common/components/ResponsiveImg';
import { grey3, skyBlueStrong } from './bedrock/SwColors';
import { SwPaper } from './bedrock/SwPaper';
import { colors } from './bedrock/fundations/colors';
import SwLink from './bedrock/typography/SwLink';
import SwTypography from './bedrock/typography/SwTypography';

export type SwMapCardProps = {
    location: {
        addressLine1?: string;
        addressLine2?: string;
        city: string;
        photo?: {
            alt: string;
            gatsbyImageData: GatsbyImageProps['image'];
        };
        country: string;
        abstract?: string;
        postcode?: string;
        coordinate?: {
            features: { geometry: { coordinates: [number, number] } }[];
        };
    };
};

export const SwMapCard: React.FC<SwMapCardProps> = (props) => {
    const { location } = props;

    const displayedLocation = [location.addressLine1, [location.postcode, location.city], location.country]
        .map((value) => (Array.isArray(value) ? value.filter(Boolean).join(' ') : value))
        .filter(Boolean)
        .join(', ');

    const staticMap = useMemo(() => {
        const [lat, long] = location.coordinate?.features[0]?.geometry.coordinates ?? [null, null];

        return lat && long
            ? {
                  xs: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+${skyBlueStrong.replace(
                      '#',
                      ''
                  )}(${lat},${long})/${lat},${long},17,0/450x450?access_token=${
                      process.env.GATSBY_MAPBOX_ACCESS_TOKEN
                  }`,
                  md: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+${skyBlueStrong.replace(
                      '#',
                      ''
                  )}(${lat},${long})/${lat},${long},16,0/800x800?access_token=${process.env.GATSBY_MAPBOX_ACCESS_TOKEN}`
              }
            : null;
    }, [location]);

    const redirectUrl = useMemo(() => {
        const url = new URL('https://www.google.com/maps/search/');
        const urlParams = new URLSearchParams([
            ['api', '1'],
            ['query', displayedLocation]
        ]).toString();

        return new URL(`${url.origin}${url.pathname}?${urlParams}`).toString();
    }, [displayedLocation]);

    return (
        <SwPaper display={'flex'} flexDirection={'column'} p={0}>
            {Boolean(location?.photo || location?.abstract) && (
                <Stack borderBottom={`1px solid ${colors.border}`} p={4} spacing={2}>
                    <SwTypography component={'h2'} variant={'h4'}>
                        Location
                    </SwTypography>
                    <SwTypography color={colors.text.secondary} component={'p'} variant={'body2'}>
                        {location.abstract}
                    </SwTypography>
                    {Boolean(location.photo) && (
                        <GatsbyImage
                            alt={location.photo.alt}
                            image={location.photo.gatsbyImageData}
                            objectFit={'cover'}
                            style={{
                                width: '100%',
                                height: 'auto',
                                position: 'relative',
                                borderRadius: 8,
                                zIndex: 0,
                                aspectRatio: AspectRatios.widescreen
                            }}
                        />
                    )}
                </Stack>
            )}
            <ResponsiveImg
                alt={'Event Map'}
                loading={'lazy'}
                srcSet={{
                    xs: { height: 450, width: 450, src: staticMap.xs },
                    md: { width: 700, height: 700, src: staticMap.md }
                }}
            />
            <Stack borderTop={`1px solid ${colors.border}`} p={4} spacing={1}>
                <SwTypography color={grey3} variant={'body2'}>
                    {displayedLocation}
                </SwTypography>
                <SwLink bold={true} color={grey3} href={redirectUrl} rel={'noopener'} target={'_blank'}>
                    Open in Maps
                </SwLink>
            </Stack>
        </SwPaper>
    );
};
