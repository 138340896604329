import React from 'react';
import { SwContentLayout } from '@lib/components/SwContentLayout';
import { SwMapCard, SwMapCardProps } from '@lib/components/SwMapCard';
import { SwSection } from '@lib/components/SwSection';

export type EventLocationProps = SwMapCardProps;

export const EventLocation: React.FC<EventLocationProps> = (props) => {
    const { location } = props;

    return (
        <SwContentLayout.Main>
            <SwSection target={location?.abstract ? 'location-map' : 'location'} width={'100%'}>
                <SwMapCard location={location} />
            </SwSection>
        </SwContentLayout.Main>
    );
};
