import React from 'react';
import { styled, Box, BoxProps } from '@mui/material';

export type SwTargetLinkProps = BoxProps<'span'> & {
    offset?: number;
    target: string;
};

const Target = styled(Box)<Pick<SwTargetLinkProps, 'offset'>>`
    transform: translateY(${({ offset }) => (offset ? -offset : -64)}px);
    width: 100%;
    display: block;
`;

export const SwTargetLink: React.FC<SwTargetLinkProps> = (props) => {
    const { target, ...rest } = props;

    return <Target id={target} {...rest} />;
};
