import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { SwCompanyCard, SwCompanyCardProps } from '@lib/components/SwCompanyCard';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';

export type EventSponsorsProps = {
    sponsors: {
        id: string;
        company: SwCompanyCardProps['company'];
    }[];
};

export const EventSponsors: React.FC<EventSponsorsProps> = (props) => {
    const { sponsors } = props;
    const { t } = useTranslation();

    return (
        <SwPaper padding={4}>
            <Stack spacing={2}>
                <SwTypography component={'h2'} variant={'h4'}>
                    {t('event_page.sponsors_thank_you_text')}
                </SwTypography>
                <SwGrid columns={{ xs: 1, sm: 2 }}>
                    {sponsors.map((sponsor) => (
                        <SwCompanyCard category={'Sponsor'} company={sponsor.company} key={sponsor.id} />
                    ))}
                </SwGrid>
            </Stack>
        </SwPaper>
    );
};
