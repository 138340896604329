import React from 'react';
import VideoPlayer from 'react-player/vimeo';
import { Box } from '@mui/material';

export type EventTrailerProps = {
    trailer: {
        externalUrl: string;
    };
};

export const EventTrailer = (props) => {
    const { trailer } = props;

    return (
        <Box>
            <Box
                borderRadius={'8px'}
                overflow={'hidden'}
                position={'relative'}
                style={{ aspectRatio: '16/9' }}
                width={'100%'}
            >
                <VideoPlayer
                    config={{
                        playerOptions: {
                            responsive: true,
                            height: 630
                        }
                    }}
                    controls={true}
                    height={'100%'}
                    style={{ position: 'absolute', inset: 0 }}
                    url={trailer.externalUrl}
                    width={'100%'}
                />
            </Box>
        </Box>
    );
};
