import { CheckCircle, UserRectangle, ArrowSquareIn } from '@phosphor-icons/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';
import { useLocalstorageState } from 'rooks';
import { HubspotForm } from '@lib/components/SwFormBuilder/HubspotForm';
import { usePublicIp } from '@lib/components/SwFormBuilder/ip';
import { IHubspotFormDefinition } from '@lib/components/SwFormBuilder/shared';
import { algaeGreenStrong, grey3 } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { colors } from '@lib/components/bedrock/fundations/colors';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import SwLink from '@lib/components/bedrock/typography/SwLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';

export type EventRegistrationProps = {
    formDefinition: IHubspotFormDefinition;
    id: string;
    eventId: string;
    title: string;
    hasInternalRegistration: boolean;
    hasExternalRegistration: boolean;
    isRegistrationOpened: boolean;
    isRegistered: boolean;
    registrationUrl?: string;
    locale: string;
    formName?: string;
    displayText: string;
};

const buildFormName = (locale: string, formName?: string) => `${formName} ${locale.toUpperCase()}`;

const Title = styled(SwTypography)`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const EventRegistration: React.FC<EventRegistrationProps> = (props) => {
    const {
        formDefinition,
        hasInternalRegistration,
        isRegistered,
        isRegistrationOpened,
        hasExternalRegistration,
        registrationUrl,
        displayText,
        locale,
        id,
        eventId,
        formName,
        title
    } = props;

    const { t } = useTranslation();
    const ip = usePublicIp();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setRegistered] = useLocalstorageState(eventId, false);

    return (
        <>
            {hasInternalRegistration && isRegistrationOpened ? (
                isRegistered ? (
                    <SwPaper bgcolor={'white'} width={'100%'}>
                        <Stack alignItems={'center'} direction={'row'} gap={1}>
                            <CheckCircle color={algaeGreenStrong} size={20} />{' '}
                            {t('event_registration_success_message', { title })}
                        </Stack>
                    </SwPaper>
                ) : (
                    <SwPaper id={id}>
                        <Stack gap={2}>
                            <Title bold={true} component={'h2'} variant={'h5'}>
                                <UserRectangle size={20} /> Register
                            </Title>
                            <HubspotForm
                                form={formDefinition}
                                ipAddress={ip}
                                values={{ form_name: buildFormName(locale, formName) }}
                                onSuccess={() => setRegistered(true)}
                            />
                        </Stack>
                    </SwPaper>
                )
            ) : null}
            {hasExternalRegistration ? (
                <SwPaper width={'100%'}>
                    <Stack alignItems={'start'} gap={2}>
                        <Stack gap={1}>
                            <SwTypography
                                alignItems={'center'}
                                bold={true}
                                component={'h2'}
                                display={'flex'}
                                gap={1}
                                variant={'h5'}
                            >
                                <UserRectangle size={20} /> {t('register')}
                            </SwTypography>
                            <SwTypography color={colors.text.secondary} variant={'body2'}>
                                {isRegistrationOpened ? (
                                    displayText
                                ) : (
                                    <Trans i18nKey={'event_registration_ended_message'}>
                                        <SwLink color={grey3} href={WEBSITE_MENU_ITEM.events.link} />
                                    </Trans>
                                )}
                            </SwTypography>
                        </Stack>
                        <SwInternalLink
                            disabled={!isRegistrationOpened}
                            href={registrationUrl}
                            rel={'noopener'}
                            replace={false}
                            target={'_blank'}
                        >
                            {t('register')} <ArrowSquareIn />
                        </SwInternalLink>
                    </Stack>
                </SwPaper>
            ) : null}
        </>
    );
};
