import { ArrowRight } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, useMediaQuery, useTheme, styled, Stack } from '@mui/material';
import { KeyPrefix } from 'i18next';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import SwCard from '../../../components/v1/SwCard';
import { white, black, grey5 } from '../../../lib/components/bedrock/SwColors';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import { zIndexes } from '../../../lib/enums/zIndexes';
import { CDNClient } from '../../../lib/helpers/image';
import LazyAutoPlayingVideo from './LazyVideo';

const MobileBox = styled(Box)`
    border-radius: 8px 8px 0 0;
    background: black;
`;

const StyledVideo = styled(LazyAutoPlayingVideo)`
    object-fit: cover;
    block-size: 100%;
    display: block;
    min-inline-size: 100%;
    min-block-size: 100%;
`;

const StyledVideoContainer = styled('div')`
    overflow: hidden;
    border-radius: ${() => (useMediaQuery(useTheme().breakpoints.down('md')) ? '0 0 8px 8px' : '8px')};
    border: 1px solid ${grey5};
    aspect-ratio: 521 / 228;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        aspect-ratio: 1/1;
    }
`;

const DesktopBox = styled(Box)`
    position: absolute;
    top: 16px;
    left: 16px;
    max-width: 35%;
`;

const ReadyToGetYourCarbonOnTrackSection: React.FC<
    BoxProps & {
        title?: KeyPrefix<'translation'>;
        titleHeading?: 1 | 2 | 3 | 4;
        noAction?: boolean;
    }
> = ({ title = 'ready_to_get_your_carbon_on_track', titleHeading = 2, noAction, ...rest }) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    return (
        <Box mt={3} position={'relative'} width={'100%'} {...rest}>
            <MobileBox p={3} sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box pb={2}>
                    <SwTypography color={white} component={`h${titleHeading ?? 4}`} variant={'h4'}>
                        {t(title)}
                    </SwTypography>
                </Box>
                {noAction ? null : (
                    <SwInternalLink to={WEBSITE_MENU_ITEM.getInTouch.link} variant={'secondary'}>
                        <ArrowRight /> {t('get_in_touch')}
                    </SwInternalLink>
                )}
            </MobileBox>
            <DesktopBox sx={{ display: { xs: 'none', md: 'block' } }} zIndex={zIndexes.EDIT_OVERLAY}>
                <SwCard background={black} borderColor={'transparent'}>
                    <Stack alignItems={'start'} p={3} spacing={2}>
                        <SwTypography color={white} component={`h${titleHeading ?? 4}`} variant={'h4'}>
                            {t(title)}
                        </SwTypography>

                        {noAction ? null : (
                            <SwInternalLink to={WEBSITE_MENU_ITEM.getInTouch.link} variant={'secondary'}>
                                <ArrowRight /> {t('get_in_touch')}
                            </SwInternalLink>
                        )}
                    </Stack>
                </SwCard>
            </DesktopBox>
            <StyledVideoContainer>
                <StyledVideo
                    height={'456px'}
                    poster={CDNClient.buildURL('/journey/bridge_construction_coast.png', {
                        auto: 'format'
                    })}
                    srcSet={[
                        {
                            src: mobile
                                ? 'https://sweep.imgix.net/home/mobile/bridge.mp4'
                                : 'https://sweep.imgix.net/finance/desktop/bridge.mp4',
                            type: 'video/mp4'
                        }
                    ]}
                    width={'100%'}
                />
            </StyledVideoContainer>
        </Box>
    );
};

export default ReadyToGetYourCarbonOnTrackSection;
