import React from 'react';
import { Theme, useTheme, styled } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';

type ImgAttrs = { src: string; height: number; width: number };

type SrcSet = Partial<Record<keyof Theme['breakpoints']['values'], ImgAttrs>>;

export type ResponsiveImageProps = React.HTMLAttributes<HTMLPictureElement> & {
    srcSet: SrcSet;
    alt: string;
    loading?: 'eager' | 'lazy';
};

const StyledImg = styled('img')`
    max-inline-size: 100%;
    block-size: 100%;
    inline-size: 100%;
    display: block;
`;

const buildBreakPointsMap = (theme: Theme) =>
    new Map([
        [theme.breakpoints.values['xs'], 'xs'],
        [theme.breakpoints.values['sm'], 'sm'],
        [theme.breakpoints.values['md'], 'md'],
        [theme.breakpoints.values['lg'], 'lg'],
        [theme.breakpoints.values['xl'], 'xl']
    ]);

const ResponsiveImg: React.FunctionComponent<ResponsiveImageProps> = (props) => {
    const { srcSet, alt, loading, ...rest } = props;
    const theme = useTheme();
    const breakpointValueMap = buildBreakPointsMap(theme);

    if (!Object.keys(srcSet).length && Object.keys(srcSet).length > 1) {
        throw new Error('You need to pass at least two srcSet');
    }

    const [defaultImg, ...restImgs] = Object.keys(srcSet)
        .map((breakpoint: Breakpoint) => theme.breakpoints.values[breakpoint])
        .sort((a, b) => a - b)
        .map((breakpointValue: number) => breakpointValueMap.get(breakpointValue))
        .map((breakpoint: Breakpoint) => ({ breakpoint, ...srcSet[breakpoint] }));

    const buildSources = restImgs.reverse().map((attr, index) => {
        return (
            <source
                key={index}
                media={`(min-width: ${theme.breakpoints.values[attr.breakpoint]}px)`}
                srcSet={attr.src}
            />
        );
    });

    return (
        <picture {...rest}>
            {buildSources}
            <StyledImg
                alt={alt}
                height={defaultImg.height}
                loading={loading}
                src={defaultImg.src}
                width={defaultImg.width}
            />
        </picture>
    );
};

export default ResponsiveImg;
