import { TableRow, styled } from '@mui/material';
import { skyBlueLight } from '../SwColors';

export const SwTableRow = styled(TableRow)`
    display: flex;
    cursor: ${(props) => (props.onClick ? 'pointer' : 'initial')};
    min-height: 40px;
    width: 100%;

    &:hover {
        background-color: ${({ onClick }) => (onClick ? skyBlueLight : 'unset')};
    }
`;
