import React, { CSSProperties } from 'react';
import { Box, styled } from '@mui/material';
import clsx from 'clsx';
import { Link, GatsbyLinkProps } from 'gatsby';
import { zIndexes } from '@lib/enums/zIndexes';
import { boldFontFamily } from '../../../../BaseLayout';
import { black, grey3, grey5 } from '../SwColors';
import { SwContainer } from '../layout/SwContainer';

export type SwSubNavProps = {
    children?: React.ReactNode;
    gap?: number;
    position?: CSSProperties['position'];
};

export const SUB_NAV_HEIGHT = 54;

export type SwSubNabItemProps = Omit<GatsbyLinkProps<any>, 'ref'> & {
    to: string;
    selected?: boolean;
    children: React.ReactNode;
};

const StyledBox = styled(Box)`
    overflow: hidden;

    z-index: ${zIndexes.TOOLTIP_BELOW_APP_BAR};
    border-bottom: 1px solid ${grey5};
    height: ${SUB_NAV_HEIGHT}px;
    display: flex;
    align-items: center;
    border-top: 1px solid ${grey5};
    border-bottom: 1px solid ${grey5};

    @supports (backdrop-filter: blur(4px)) {
        backdrop-filter: blur(6px);
        background-color: rgba(255, 255, 255, 0.5);
    }

    @supports not (backdrop-filter: blur(4px)) {
        background-color: rgba(255, 255, 255, 0.9);
    }
`;

const NavItems = styled('ul')<{ gap?: number }>`
    display: flex;
    max-width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: ${({ gap }) => gap ?? 8}px;
    align-items: center;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        & > *:first-child {
            padding-inline-start: 20px;
        }

        & > *:last-child {
            padding-inline-end: 20px;
        }
    }
`;

const StyledLink = styled(Link)`
    font-weight: normal;
    color: ${black};
    text-decoration: none;
    cursor: pointer;

    :hover {
        color: ${grey3};
    }

    &.-selected {
        font-family: ${boldFontFamily};
    }
`;

const Item: React.FC<SwSubNabItemProps> = (props) => {
    const { selected = false, to, children, className, ...rest } = props;

    return (
        <StyledLink
            className={clsx(className, {
                '-selected': selected
            })}
            to={to}
            {...rest}
        >
            {children}
        </StyledLink>
    );
};

export const SwSubNav: React.FC<SwSubNavProps> & { Item: React.FC<SwSubNabItemProps> } = (props: SwSubNavProps) => {
    const { children, gap, position = 'relative', ...rest } = props;

    return (
        <StyledBox component={'nav'} position={position} {...rest}>
            <SwContainer hasOverflowingContent={true}>
                <NavItems gap={gap}>{children}</NavItems>
            </SwContainer>
        </StyledBox>
    );
};

SwSubNav.Item = Item;
