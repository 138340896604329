import React from 'react';
import { Stack } from '@mui/material';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { AspectRatios } from '@lib/enums/apectRatios';
import { boldFontFamily } from '../../BaseLayout';
import SwMediaCard from './SwMediaCard';
import { grey3 } from './bedrock/SwColors';
import { colors } from './bedrock/fundations/colors';
import { SwInternalLink } from './bedrock/navigation/SwInternalLink';
import SwTypography from './bedrock/typography/SwTypography';

export type SwCompanyCardProps = {
    company: {
        name: string;
        description?: string;
        website?: string;
        logo: {
            alt: string;
            gatsbyDataImage: GatsbyImageProps['image'];
        };
    };
    category?: string;
};

export const SwCompanyCard: React.FC<SwCompanyCardProps> = (props) => {
    const { company, category } = props;

    return (
        <SwMediaCard
            aspectRatio={AspectRatios.widescreen}
            background={'white'}
            layout={'content-bottom'}
            media={
                <GatsbyImage
                    alt={company.logo.alt}
                    image={company.logo.gatsbyDataImage}
                    objectFit={'contain'}
                    style={{ width: '60%' }}
                />
            }
            mediaContainerStyle={{ display: 'grid', placeItems: 'center' }}
        >
            <Stack alignItems={'start'} spacing={2}>
                <Stack spacing={1}>
                    {Boolean(category) && (
                        <SwTypography bold={true} color={colors.text.secondary} uppercase={true} variant={'caption'}>
                            {category}
                        </SwTypography>
                    )}
                    <SwTypography variant={'headingStrong'}>{company.name}</SwTypography>
                </Stack>
                {company.description ? (
                    <SwTypography color={colors.text.secondary} variant={'body2'}>
                        {company.description}
                    </SwTypography>
                ) : null}
                {company.website && (
                    <SwInternalLink
                        href={company.website}
                        rel={'nooponer'}
                        size={'small'}
                        style={{
                            marginLeft: -8,
                            fontFamily: boldFontFamily,
                            color: grey3
                        }}
                        target={'_blank'}
                        variant={'link'}
                    >
                        Website
                    </SwInternalLink>
                )}
            </Stack>
        </SwMediaCard>
    );
};
