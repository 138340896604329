import React from 'react';
import { Stack } from '@mui/material';
import { SwCollapsibleSection } from '@lib/components/SwCollapsibleSection';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { grey3 } from '@lib/components/bedrock/SwColors';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwLink from '@lib/components/bedrock/typography/SwLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';

export type RelatedArticlesProps = {
    title?: string;
    relatedArticles: any[];
};

export const RelatedArticles: React.FC<RelatedArticlesProps> = (props) => {
    const { title = 'Related articles', relatedArticles } = props;

    return (
        <SwCollapsibleSection
            backgroundColor={'transparent'}
            defaultExpanded={true}
            title={<SwTypography variant={'bodyStrong'}>{title}</SwTypography>}
        >
            <Stack spacing={2}>
                <SwGrid columns={{ xs: 1, sm: 2 }}>
                    {relatedArticles.map((article) => (
                        <SwRecordCard
                            baseUrl={
                                WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()]?.link ??
                                WEBSITE_MENU_ITEM.blog.link
                            }
                            key={article.id}
                            record={article}
                            titleHeading={'h3'}
                            variant={'compact'}
                        />
                    ))}
                </SwGrid>
                <SwTypography color={grey3} variant={'body2'}>
                    Browse our library for{' '}
                    <SwLink color={grey3} href={WEBSITE_MENU_ITEM.library.link}>
                        related content
                    </SwLink>
                    , or search for{' '}
                    <SwLink color={grey3} href={WEBSITE_MENU_ITEM.events.link}>
                        other events
                    </SwLink>
                    .
                </SwTypography>
            </Stack>
        </SwCollapsibleSection>
    );
};
