import { TableCell, styled, tableCellClasses } from '@mui/material';
import { boldFontFamily } from '../../../../BaseLayout';
import { grey3, grey5 } from '../SwColors';

export const SwTableCell = styled(TableCell)`
    padding: ${(props) => props.theme.spacing(props.size === 'small' ? 0.5 : 1)} ${({ theme }) => theme.spacing(2)};
    border-right: 1px solid ${grey5};
    border-bottom: 1px solid ${grey5};
    display: flex;
    align-items: center;
    width: 100%;

    &.${tableCellClasses.head} {
        font-family: ${boldFontFamily};
        font-size: ${({ theme }) => theme.typography.caption.fontSize};
        line-break: ${({ theme }) => theme.typography.caption.lineBreak};
        color: ${grey3};
    }

    &.${tableCellClasses.body} {
        font-family: ${({ theme }) => theme.typography.body2.fontFamily};
        font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
        font-size: ${({ theme }) => theme.typography.body2.fontSize};
        line-break: ${({ theme }) => theme.typography.body2.lineBreak};
        color: ${grey3};
    }
`;
