import { ArrowSquareIn, CaretRight, CheckCircle } from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery, styled, Stack, Box } from '@mui/material';
import { useLocation } from '@reach/router';
import dayjs from 'dayjs';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocalstorageState } from 'rooks';
import { SwContentCard } from '@lib/components/SwContentCard';
import { SwContentLayout } from '@lib/components/SwContentLayout';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { SwRichContent } from '@lib/components/SwRichContent';
import { SwSection } from '@lib/components/SwSection';
import { SwSharingLinksSection } from '@lib/components/SwSharingLinksSection';
import { SwStat } from '@lib/components/SwStat';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { colors } from '@lib/components/bedrock/fundations/colors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { SwSubNav } from '@lib/components/bedrock/navigation/SwSubNav';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { AspectRatios } from '@lib/enums/apectRatios';
import ReadyToGetYourCarbonOnTrackSection from '@app/common/components/ReadyToGetYourCarbonOnTrackSection';
import WebsiteSectionsLayout from '@app/common/components/WebisteSectionsLayout';
import { EventInformation } from '@app/events/EventInformation';
import { EventLocation } from '@app/events/EventLocation';
import { EventMeta } from '@app/events/EventMeta';
import { EventRegistration } from '@app/events/EventRegistration';
import { EventSessions } from '@app/events/EventSessions';
import { EventSponsors } from '@app/events/EventSponsors';
import { EventTrailer } from '@app/events/EventTrailer';
import { HostCard } from '@app/events/HostsCard';
import { RelatedArticles } from '@app/events/RelatedArticles';
import FlashBanner from '../app/common/components/FlashBanner';
import Seo from '../app/common/components/Seo';
import SwLayout from '../components/v1/SwLayout';
import SwBreadcrumb from '../components/v1/website/SwBreadcrumb';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import { black, grey3, grey5, grey6 } from '../lib/components/bedrock/SwColors';
import SwWebsiteContainer from '../lib/components/layout/SwWebsiteContainer';

const Header = styled('div')`
    padding-block-start: 32px;
`;

const CoverImage = styled('div')`
    position: relative;
    width: 100%;
    aspect-ratio: ${AspectRatios.widescreen};
    max-height: 580px;
    min-height: 380px;
    background: ${grey5};
    border-radius: 8px;
    z-index: 0;
    border: 1px solid ${grey5};
    overflow: hidden;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        min-height: auto;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        aspect-ratio: ${AspectRatios.portrait};
    }
`;

const SidebarContainer = styled('div')`
    display: flex;
    gap: 16px;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-inline: 20px;
    }
`;

const StyledEventInformation = styled(EventInformation)`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    max-width: 330px;
    border-top: none;
    border-top-left-radius: 0;
    border-left: none;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        position: relative;
        top: unset;
        left: unset;
        max-width: 100%;
        border-top-left-radius: 8px;
        border-left: 1px solid ${colors.border};
        border-top: 1px solid ${colors.border};
    }
`;

const FORM_ID = 'registration-form';

const SECTIONS = {
    overview: 'overview',
    speakers: 'speakers',
    sessions: 'sessions',
    location: 'location',
    sponsors: 'sponsors',
    registration: 'registration'
} as const;

const EventTemplate = ({ data, pageContext: { slug } }) => {
    const event = data.graphCmsEvent;
    const availableLocalizations = data.eventLocalizations.nodes.filter(({ locale }) =>
        data.eventLocalizations.distinct.includes(locale)
    );
    const isTabletOrLess = useMediaQuery(useTheme().breakpoints.down('md'));
    const { t } = useTranslation();
    const [isRegistered] = useLocalstorageState(event.id, false);
    const { hash } = useLocation();
    const { search } = useLocation();

    const isEventFinished = dayjs().isAfter(new Date(event.endDate));
    const isEventRunning = dayjs().isAfter(new Date(event.startDate)) && dayjs().isBefore(new Date(event.endDate));
    const isRegistrationOpened = !(isEventRunning || isEventFinished);
    const hasExternalRegistration = Boolean(event.registrationUrl);
    const hasInternalRegistration = Boolean(event.formName);
    const isSimpleTemplate = event.template === 'simple';
    const isFullTemplate = event.template === 'full';

    const displayText = event.location
        ? event.isOnline
            ? t('event_page.location_text_online_onsite', { location: event.location.city })
            : t('event_page.location_text_onsite', { location: event.location.city })
        : t('event_page.location_text_online');

    const subNavItems: Array<{ target: keyof typeof SECTIONS; label: (typeof SECTIONS)[keyof typeof SECTIONS] }> =
        useMemo(
            () =>
                [
                    { target: 'overview', label: 'overview' } as const,
                    { target: 'speakers', label: 'speakers' } as const,
                    event.sessions?.length ? ({ target: 'sessions', label: 'sessions' } as const) : null,
                    event.location ? ({ target: 'location', label: 'location' } as const) : null,
                    event.sponsors?.length ? ({ target: 'sponsors', label: 'sponsors' } as const) : null,
                    { target: 'registration', label: 'registration' } as const
                ].filter(Boolean),
            [event]
        );

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.resources.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
                localizedSlugs={availableLocalizations.map(({ locale, slug }) => ({
                    locale,
                    slug: `/${slug}`
                }))}
            />
            <SwWebsiteContainer>
                <SwLayout background={grey6}>
                    <SwBreadcrumb
                        basePage={WEBSITE_MENU_ITEM.events.text}
                        baseUrl={WEBSITE_MENU_ITEM.events.link}
                        page={event.category.label}
                    />
                    <WebsiteSectionsLayout>
                        <div>
                            <SwContainer paddingBlock={'0 32px'}>
                                <SwGrid columns={1} gap={16}>
                                    <Header>
                                        <SwGrid columns={{ xs: ['100%'] }} gap={32}>
                                            <SwGrid.Item column={1}>
                                                <SwGrid columns={{ xs: ['100%'], md: ['60%', 'auto'] }} gap={32}>
                                                    <SwTypography color={black} component={'h1'} variant={'h3'}>
                                                        {event.title}
                                                    </SwTypography>
                                                    {event.subtitle && (
                                                        <SwTypography
                                                            color={colors.text.secondary}
                                                            component={'p'}
                                                            variant={'h3'}
                                                        >
                                                            {event.subtitle}
                                                        </SwTypography>
                                                    )}
                                                </SwGrid>
                                            </SwGrid.Item>
                                            <SwGrid.Item column={{ xs: 1, md: 2 }}>
                                                <SwFlexBox flexDirection={'column'} gap={16}>
                                                    <CoverImage>
                                                        {!isTabletOrLess && (
                                                            <StyledEventInformation
                                                                isOnline={event.isOnline}
                                                                isRegistered={isRegistered}
                                                                isRegistrationClosed={!isRegistrationOpened}
                                                                language={event.language}
                                                                location={event.location}
                                                                registrationUrl={event.registrationUrl}
                                                                startDate={event.startDate}
                                                            />
                                                        )}
                                                        {event.coverImage ? (
                                                            <GatsbyImage
                                                                alt={event.coverImage.alt}
                                                                image={event.coverImage.gatsbyImageData}
                                                                objectFit={'cover'}
                                                                style={{ height: '100%', width: '100%' }}
                                                            />
                                                        ) : null}
                                                    </CoverImage>
                                                    {isTabletOrLess && (
                                                        <StyledEventInformation
                                                            isOnline={event.isOnline}
                                                            isRegistrationClosed={!isRegistrationOpened || isRegistered}
                                                            language={event.language}
                                                            location={event.location}
                                                            registrationUrl={event.registrationUrl}
                                                            startDate={event.startDate}
                                                        />
                                                    )}
                                                </SwFlexBox>
                                            </SwGrid.Item>
                                        </SwGrid>
                                    </Header>
                                </SwGrid>
                            </SwContainer>
                            <SwSubNav gap={32}>
                                {subNavItems.map((item) => (
                                    <SwSubNav.Item
                                        key={item.target}
                                        replace={false}
                                        selected={hash === `#${item.target}`}
                                        to={`${search}#${item.target}`}
                                    >
                                        {t(item.label)}
                                    </SwSubNav.Item>
                                ))}
                            </SwSubNav>

                            <SwSection target={'overview'}>
                                <SwContainer>
                                    {Boolean(event.stats?.length) && (
                                        <Box pt={4}>
                                            <SwGrid columns={{ xs: 1, sm: 3 }}>
                                                {event.stats.map((stat) => (
                                                    <SwStat key={stat.id} {...stat} />
                                                ))}
                                            </SwGrid>
                                            <SwLine
                                                color={colors.border}
                                                direction={'horizontal'}
                                                spacingBottom={0}
                                                spacingTop={4}
                                            />
                                        </Box>
                                    )}
                                </SwContainer>
                                <SwContainer paddingInline={{ xs: '0px' }}>
                                    <SwContentLayout
                                        leftSide={
                                            <SidebarContainer>
                                                <EventMeta event={event} />
                                            </SidebarContainer>
                                        }
                                        rightSide={
                                            <SidebarContainer>
                                                {!isTabletOrLess && (
                                                    <SwPaper>
                                                        <Stack alignItems={'start'} spacing={1}>
                                                            <SwTypography color={grey3} variant={'body2'}>
                                                                {isRegistrationOpened
                                                                    ? displayText
                                                                    : 'Registration ended'}
                                                            </SwTypography>
                                                            <SwInternalLink
                                                                disabled={!isRegistrationOpened}
                                                                href={event.registrationUrl}
                                                                readonly={isRegistered}
                                                                rel={event.registrationUrl ? 'noopener' : undefined}
                                                                replace={false}
                                                                target={event.registrationUrl ? '_blank' : undefined}
                                                                to={
                                                                    event.registrationUrl
                                                                        ? undefined
                                                                        : `${search}#registration-form`
                                                                }
                                                                variant={isRegistered ? 'success' : 'default'}
                                                                withI18n={Boolean(event.registrationUrl)}
                                                            >
                                                                {isRegistered ? (
                                                                    <>
                                                                        Registered
                                                                        <CheckCircle />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        Register
                                                                        {hasExternalRegistration ? (
                                                                            <ArrowSquareIn />
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </SwInternalLink>
                                                        </Stack>
                                                    </SwPaper>
                                                )}
                                                <SwSharingLinksSection
                                                    abstract={event.abstract}
                                                    slug={slug}
                                                    title={event.title}
                                                />
                                            </SidebarContainer>
                                        }
                                    >
                                        {event.relatedArticles.length && isEventFinished ? (
                                            <SwContentLayout.Main withPaddingOnMobile={true}>
                                                <RelatedArticles
                                                    relatedArticles={event.relatedArticles}
                                                    title={t('past_event')}
                                                />
                                            </SwContentLayout.Main>
                                        ) : null}
                                        <SwContentCard content={event.content} />
                                        {isSimpleTemplate && (
                                            <>
                                                <SwContentLayout.Main withPaddingOnMobile={true}>
                                                    <SwSection target={'speakers'}>
                                                        <SwGrid columns={{ xs: 1, sm: 2 }}>
                                                            {event.hosts.map((host) => (
                                                                <HostCard host={host} key={host.id} showRole={false} />
                                                            ))}
                                                        </SwGrid>
                                                    </SwSection>
                                                </SwContentLayout.Main>
                                                {Boolean(event.location) && <EventLocation location={event.location} />}
                                                <SwContentLayout.Main withPaddingOnMobile={true}>
                                                    <SwSection target={'registration'}>
                                                        <EventRegistration
                                                            displayText={displayText}
                                                            eventId={event.id}
                                                            formDefinition={data.eventForm}
                                                            formName={event.formName}
                                                            hasExternalRegistration={hasExternalRegistration}
                                                            hasInternalRegistration={hasInternalRegistration}
                                                            id={FORM_ID}
                                                            isRegistered={isRegistered}
                                                            isRegistrationOpened={isRegistrationOpened}
                                                            locale={event.locale}
                                                            registrationUrl={event.registrationUrl}
                                                            title={event.title}
                                                        />
                                                    </SwSection>
                                                </SwContentLayout.Main>
                                            </>
                                        )}
                                        {event.relatedArticles.length && !isEventFinished ? (
                                            <SwContentLayout.Main withPaddingOnMobile={true}>
                                                <RelatedArticles relatedArticles={event.relatedArticles} />
                                            </SwContentLayout.Main>
                                        ) : null}
                                    </SwContentLayout>
                                </SwContainer>
                                {isFullTemplate && Boolean(event.trailer) && (
                                    <SwContainer>
                                        <SwLine
                                            color={colors.border}
                                            direction={'horizontal'}
                                            spacingBottom={4}
                                            spacingTop={0}
                                        />
                                        <EventTrailer trailer={event.trailer} />
                                    </SwContainer>
                                )}
                            </SwSection>

                            {isFullTemplate && (
                                <>
                                    <SwContainer>
                                        <SwLine spacing={4} />
                                        <SwSection target={'speakers'}>
                                            <SwGrid columns={{ xs: 1, sm: 2, md: 4 }}>
                                                {event.hosts.map((host) => (
                                                    <HostCard host={host} key={host.id} sessions={event.agenda} />
                                                ))}
                                            </SwGrid>
                                        </SwSection>
                                        <SwLine spacing={4} />
                                    </SwContainer>
                                    {Boolean(event.agenda.length) && (
                                        <>
                                            <SwContainer maxInlineSize={776 + 2 * 64}>
                                                <EventSessions sessions={event.agenda} />
                                            </SwContainer>
                                            <SwContainer>
                                                <SwLine spacing={4} />
                                            </SwContainer>
                                        </>
                                    )}
                                    <SwContainer>
                                        <SwContentLayout leftSide={null} rightSide={null}>
                                            {Boolean(event.cards?.length) &&
                                                event.cards.map((card) => (
                                                    <SwContentLayout.Main key={card.id}>
                                                        <SwPaper
                                                            display={'flex'}
                                                            flexDirection={'column'}
                                                            gap={1}
                                                            p={4}
                                                        >
                                                            {Boolean(card.title) && (
                                                                <SwTypography component={'h2'} variant={'h4'}>
                                                                    {card.title}
                                                                </SwTypography>
                                                            )}
                                                            <SwRichContent content={card.content.raw} />
                                                        </SwPaper>
                                                    </SwContentLayout.Main>
                                                ))}
                                            {Boolean(event.location) && <EventLocation location={event.location} />}
                                            {Boolean(event.sponsors.length) && (
                                                <SwContentLayout.Main>
                                                    <SwSection target={'sponsors'}>
                                                        <EventSponsors sponsors={event.sponsors} />
                                                    </SwSection>
                                                </SwContentLayout.Main>
                                            )}
                                        </SwContentLayout>
                                    </SwContainer>
                                    <SwLine spacing={4} />
                                    <SwContainer>
                                        <SwSection target={'registration'}>
                                            <SwGrid
                                                columns={{ xs: ['1fr'], md: ['333px', '1fr'] }}
                                                gap={{ xs: 32, md: 64 }}
                                            >
                                                <Stack maxWidth={333} spacing={1}>
                                                    <SwTypography component={'h2'} variant={'h4'}>
                                                        {t('register_today')}
                                                    </SwTypography>
                                                    <SwTypography
                                                        color={colors.text.secondary}
                                                        variant={'body2'}
                                                    ></SwTypography>
                                                </Stack>
                                                <EventRegistration
                                                    displayText={displayText}
                                                    eventId={event.id}
                                                    formDefinition={data.eventForm}
                                                    formName={event.formName}
                                                    hasExternalRegistration={hasExternalRegistration}
                                                    hasInternalRegistration={hasInternalRegistration}
                                                    id={FORM_ID}
                                                    isRegistered={isRegistered}
                                                    isRegistrationOpened={isRegistrationOpened}
                                                    locale={event.locale}
                                                    registrationUrl={event.registrationUrl}
                                                    title={event.title}
                                                />
                                            </SwGrid>
                                            <ReadyToGetYourCarbonOnTrackSection
                                                noAction={true}
                                                title={'event_page.on_track_section'}
                                            />
                                        </SwSection>
                                    </SwContainer>
                                </>
                            )}

                            <SwLine spacing={4} />
                            <SwContainer gap={32} paddingBlock={'0 16px'}>
                                <SwFlexBox alignItems={'center'} gap={4}>
                                    <SwTypography bold={true} color={grey3} component={'h2'} variant={'body1'}>
                                        {t('event_page.section_1_title')}
                                    </SwTypography>
                                    <CaretRight color={grey3} fontWeight={'bold'} />
                                </SwFlexBox>
                            </SwContainer>
                            <SwContainer hasOverflowingContent={true}>
                                <SwLatestContent
                                    content={data.moreEvents.nodes}
                                    renderItems={(event) => (
                                        <SwRecordCard
                                            key={event.id}
                                            record={event}
                                            titleHeading={'h3'}
                                            width={{ xs: 300, md: 'auto' }}
                                        />
                                    )}
                                />
                            </SwContainer>
                        </div>
                    </WebsiteSectionsLayout>

                    <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({ data: { graphCmsEvent: event }, pageContext: { slug } }) => (
    <Seo
        description={event.seo.description}
        image={event.seo.image?.url || event.coverImage.url}
        noIndex={event.seo.noIndex || event.unlisted}
        pathname={slug}
        title={event.seo.title}
    />
);

export const getEventQuery = graphql`
    query getEventPageQuery(
        $id: String!
        $remoteId: ID!
        $locale: GraphCMS_Locale = en
        $formName: String = "Event Registration EN"
    ) {
        eventForm: hubspotForm(name: { eq: $formName }) {
            ...Essentials_HubspotForm
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        eventLocalizations: allGraphCmsEvent(filter: { remoteId: { eq: $remoteId } }) {
            distinct(field: actualLocale)
            nodes {
                slug
                locale
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        moreEvents: allGraphCmsEvent(
            sort: { order: DESC, fields: startDate }
            limit: 3
            filter: { locale: { eq: $locale }, actualLocale: { eq: $locale }, id: { ne: $id }, unlisted: { eq: false } }
        ) {
            nodes {
                ...EventCard_GraphCMS_Event
                coverImage {
                    alt
                    gatsbyImageData(height: 290, layout: FIXED, placeholder: BLURRED)
                }
            }
        }
        graphCmsEvent(id: { eq: $id }) {
            id
            category {
                label
                usage
            }
            startDate
            endDate
            title
            slug
            locale
            unlisted
            formName
            registrationUrl
            relatedArticles {
                id
                title
                slug
                published
                tags {
                    id: remoteId
                    label
                    color
                }
                category {
                    label
                    usage
                }
                abstract
                coverImage {
                    alt
                    gatsbyImageData(height: 186, layout: FIXED, placeholder: BLURRED)
                }
            }
            hosts {
                ...Essentials_GraphCMS_Host
            }
            trailer {
                externalUrl
                poster {
                    alt
                    gatsbyImageData(layout: CONSTRAINED, width: 1500, placeholder: BLURRED)
                }
            }
            language
            isOnline
            location {
                addressLine1
                addressLine2
                city
                country
                postcode
                id
                abstract
                photo {
                    alt
                    gatsbyImageData(height: 300, layout: FIXED, placeholder: BLURRED)
                }
                coordinate {
                    features {
                        geometry {
                            coordinates
                        }
                    }
                }
            }
            stats {
                ...Essentials_GraphCMS_Stat
            }
            sponsors {
                id
                company {
                    ...Essentials_GraphCMS_Company
                    website
                }
            }
            template
            subtitle
            agenda {
                title
                description
                startDate
                endDate
                hosts {
                    ...Essentials_GraphCMS_Host
                }
            }
            coverImage {
                alt
                url
                gatsbyImageData(aspectRatio: 1.678, layout: CONSTRAINED, width: 1500, placeholder: BLURRED)
            }
            tags {
                id: remoteId
                label
                color
            }
            cards {
                ... on GraphCMS_SimpleCard {
                    id
                    title
                    headingLevel
                    content {
                        raw
                    }
                }
            }
            content {
                json
                references {
                    ...Essentials_GraphCMS_Asset
                    ...Essentials_GraphCMS_Quote
                    ...Essentials_GraphCMS_CustomerQuote
                    ...Essentials_GraphCMS_CallOut
                }
            }
            seo {
                ...Essentials_GraphCMS_Seo
            }
        }
    }
`;

export default EventTemplate;
